import CONSTANTS from './constants';
import { loadOutput } from './output';

const doChangeFormat = format => ({
    type: CONSTANTS.CHANGE_FORMAT,
    format
});

export const changeFormat = (format) => {
    return (dispatch) => {
        dispatch(doChangeFormat(format));
        dispatch(loadOutput());
    };
};
