import styled from 'styled-components';

import { globals } from '../../globals/index';

export const ControlC = styled.p`
    font: 300 26px/32px ${globals.fonts.title};
    color: ${globals.colours.gray};
    text-align: center;
    transform: rotate(-1deg);
    margin: 40px 0;
    user-select: none;
    cursor: pointer;

    .asterisk {
        margin-right: 4px;
    }

    .cmd {
        display: inline-block;
        position: relative;
        color: ${globals.colours.darkGray};

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 1px;
            left: -4px;
            width: calc(100% + 8px);
            height: 100%;
            background-color: ${globals.colours.yellow};
            z-index: -1;
            margin-top: 2px;
            transform: rotate(-1deg);
        }
    }
`;
