export const globals = {
    colours: {
        active: '#4C3E41',
        gray: '#9B9B9B',
        lightGray: '#D8D8D8',
        darkGray: '#4A4A4A',
        textGray: '#666666',
        pink: '#E7538D',
        yellow: '#FAD973',
        blue: '#29D6D7',
        bluePen: '#4990E2',
        blueSignature: '#8ba3cf'
    },
    sizes: {
        mobile: 767,
        bigPhablet: 550,
        tablet: 1023,
        tabletLandscape: 1024,
        smallDesktop: 1300
    },
    fonts: {
        title: '"Gloria Hallelujah", cursive',
        highlight: '"Permanent Marker", cursive'
    },
    padding: 25
};
