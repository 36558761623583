import styled from 'styled-components';

import { globals } from '../../globals/index';

export const OutputText = styled.div`
    margin: -20px 0 0 0;
    font: 300 18px/28px ${globals.fonts.title};
    color: ${globals.colours.textGray};
    padding: 20px;
    position: relative;

    @media (max-width: ${globals.sizes.mobile}px) {
        padding: 20px 0;
    } 

    p, .p {
        margin: 20px 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 22px;
        line-height: 36px;
        margin: 20px 0;
    }

    p, .p, .output-list {
        padding-left: 20px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .markup {
        color: ${globals.colours.pink};
        font-weight: normal;
        font-style: italic;
        white-space: nowrap;

        @media (max-width: ${globals.sizes.mobile}px) {
            white-space: normal;
            word-break: break-word;
        }
        
        &.html.block {
            display: block;
            margin-left: -20px;
            font-size: 22px;
            line-height: 24px;
        }
    }

    a {
        font-family: ${globals.fonts.title};
        text-decoration: none;
        color: ${globals.colours.pink};
    }

    .anchor.html {
        i {
            display: inline-block;
            width: 12px;
            overflow: hidden;
            white-space: nowrap;

            &::before {
                content: "...";
                display: inline;
            }
        }
    }

    .output-list {
        margin-top: 20px;
    }
`;
