import React from 'react';

import Translatable from '../../containers/Translatable';
import { ControlC } from './ControlC.styles';
import Events from '../../utils/Events';
import browserme from 'browserme';

const COMMAND = browserme.os.isMac() ? 'copy_cmd' : 'copy_ctrl';

const ControlCLine = () => (
    <ControlC className="control-c" onClick={() => Events.copyToClipboard()}>
        <span className="asterisk">*</span>
        <Translatable
            text={browserme.device.isMobile() ? 'copy_mobile' : COMMAND}
        />
    </ControlC>
);

export default ControlCLine;
