import React from 'react';

import H2 from './H2.component';
import { Intro, Pelayu } from './Header.styles';

import pelayuImg from '../../../images/pelayu.svg';

const IntroSection = () => (
    <Intro>
        <Pelayu src={pelayuImg} />
        <div>
            <H2 />
        </div>
    </Intro>
);

export default IntroSection;
