import CONSTANTS from '../actions/constants';

const copiedFilter = (state = false, action) => {
    switch (action.type) {
        case CONSTANTS.SHOW_COPIED:
            return true;
        case CONSTANTS.HIDE_COPIED:
            return false;
        default:
            return state;
    }
};

export default copiedFilter;
