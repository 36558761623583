import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../Footer/Footer.component';
import ControlC from '../ControlC/ControlC.component';
import Separator from './Separator';

import CopiedContainer from '../../containers/CopiedContainer';

const FooterWrapper = ({ language }) => (
    <React.Fragment>
        <Separator style="inverted" />
        <ControlC language={language} />
        <Footer language={language} />
        <CopiedContainer />
    </React.Fragment>
);

FooterWrapper.propTypes = {
    language: PropTypes.string.isRequired
};

export default FooterWrapper;
