import querystringme from 'querystringme';

import CONSTANTS from '../actions/constants';

const getInitialState = () => parseInt(querystringme.getParameter('paragraphs'), 10);

const numberParagraphFilter = (state = getInitialState(), action) => {
    switch (action.type) {
        case CONSTANTS.CHANGE_NUMBER_PARAGRAPHS:
            querystringme.updateParameters({
                paragraphs: action.number
            });
            return action.number;
        default:
            return state;
    }
};

export default numberParagraphFilter;
