import ParagraphSelector from '../components/ControlBar/ParagraphSelector.component';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeNumberParagraphs } from '../actions';

const mapStateToProps = function(state) {
    return {
        language: state.language,
        paragraphs: state.paragraphs
    };
};

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            changeNumberParagraphs
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParagraphSelector);
