import React from 'react';
import PropTypes from 'prop-types';

import { OutputText } from './Output.styles';

const renderTag = (element, index, children) => {
    const { tag, format = {}, inline, outerTag, className = '', attributes = {} } = element;
    const { open, close, name } = format;
    const compoundClassName = `markup ${inline ? 'inline' : 'block'} ${name} ${className}`;
    const Tag = tag;
    const openTag = open && (<span className={compoundClassName}>{open}</span>);
    const closeTag = close && (<span className={compoundClassName}>{close}</span>);

    return (
        <React.Fragment key={index}>
            {outerTag ? openTag : null}
            <Tag {...attributes}>
                {outerTag ? null : openTag}
                {children}
                {outerTag ? null : closeTag}
            </Tag>
            {outerTag ? closeTag : null}
        </React.Fragment>
    );
};

const renderSingleElement = (element, index, children) => {
    const { wrapper } = element;

    const content = renderTag(element, index, children);

    return wrapper ? (
        <div className={wrapper} key={index}>
            {content}
        </div>
    ) : (
        content
    );
};

const renderElements = elements => {
    return elements.map((element, index) => {
        if (typeof element === 'string') {
            return element;
        }

        const { content = [] } = element;

        return renderSingleElement(element, index, renderElements(content));
    });
};

const renderBlocks = (blocks, format) => {
    return blocks.map((block, index) => {
        return (
            <section key={index}>
                {renderElements(block.render(format))}
            </section>
        );
    });
};

const Output = ({ blocks, format }) => (
    <OutputText>
        {renderBlocks(blocks, format)}
    </OutputText>
);

Output.propTypes = {
    blocks: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired
};

export default Output;
