import Element from './Element';

import Sentence from './Sentence';

const NUMBER_SENTENCES_PER_PARAGRAPH = 10;

const createParagraph = (options = {}) => {
    const { rate, level, index, showLinks } = options;

    return new Element({
        index: index,
        rate: rate,
        level: level,
        htmlTag: `p`,
        inline: false,
        always: true,
        indent: true,
        tag: {
            html: {
                open: `<p>`,
                close: `</p>`
            },
            text: {
                open: '',
                close: ''
            },
            markdown: {
                open: '',
                close: ''
            }
        },
        content: Sentence.create({ number: NUMBER_SENTENCES_PER_PARAGRAPH, showLinks, index, indented: true })
    });
};

export default {
    create: createParagraph
};
