import Element from './Element';

import Sentence from './Sentence';

const createListElement = (options = {}) => {
    const { level, index } = options;

    return new Element({
        index: index,
        rate: 1,
        level: level,
        htmlTag: `li`,
        inline: true,
        tag: {
            html: {
                open: `<li>`,
                close: `</li>`
            },
            text: {
                open: '* ',
                close: ''
            },
            markdown: {
                open: '* ',
                close: ''
            }
        },
        content: Sentence.create({ number: 1, showLinks: false, index })
    });
};

export default {
    create: createListElement
};
