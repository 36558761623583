import querystringme from 'querystringme';

import { FORMAT_LIST } from '../components/FormatSelector/FormatSelector.component';
import { LANGUAGE_LIST } from '../components/LanguageSelector/LanguageSelector.component';
import { PARAGRAPH_LIST } from '../components/ControlBar/ParagraphSelector.component';

const load = () => {
    querystringme.load({
        localStorage: true,
        defaultValues: {
            language: {
                default: LANGUAGE_LIST[0],
                validator: (v) => LANGUAGE_LIST.includes(v)
            },
            format: {
                default: FORMAT_LIST.html.key,
                validator: (v) => FORMAT_LIST[v]
            },
            paragraphs: {
                default: '1',
                validator: function(v) {
                    const parsed = parseInt(v, 10);
                    return !isNaN(parsed) && PARAGRAPH_LIST.includes(parsed);
                }
            },
            link: {
                default: true,
                validator: (v) => v === 'true' || v === 'false'
            },
            header: {
                default: true,
                validator: (v) => v === 'true' || v === 'false'
            },
            list: {
                default: true,
                validator: (v) => v === 'true' || v === 'false'
            }
        }
    });
};

export default {
    load
};
