import ElementSelector from '../components/ControlBar/ElementSelector.component';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeElement } from '../actions';

const mapStateToProps = function(state) {
    return {
        language: state.language,
        elements: state.elements
    };
};

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            changeElement
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ElementSelector);
