import React from 'react';
import PropTypes from 'prop-types';

import { ElementControlList, ElementNav, ElementListElement, ElementAnchor } from './ElementSelector.styles';

import Translatable from '../../containers/Translatable';
import { NoteElements } from '../Note/NoteElements.styles';

export const ELEMENT_LIST = {
    link: {
        key: 'link',
        name: '<a>'
    },
    header: {
        key: 'header',
        name: '<h*>'
    },
    list: {
        key: 'list',
        name: '<li>'
    }
};

const ElementSelector = ({ elements, changeElement }) => (
    <ElementNav>
        <ElementControlList>
            {Object.keys(ELEMENT_LIST).map((key, i) => {
                const name = ELEMENT_LIST[key].name;
                const status = elements ? elements[key] : false;

                return (
                    <ElementListElement key={key} className={status ? 'active' : ''}>
                        <ElementAnchor
                            href="#"
                            key={key}
                            className={status ? 'active' : ''}
                            onClick={e => {
                                e.preventDefault();
                                changeElement(key, !elements[key]);
                            }}
                        >
                            {name}
                        </ElementAnchor>
                    </ElementListElement>
                );
            })}
        </ElementControlList>
        <NoteElements>
            <Translatable text="select_elements" />
        </NoteElements>
    </ElementNav>
);

ElementSelector.propTypes = {
    elements: PropTypes.object.isRequired,
    changeElement: PropTypes.func.isRequired
};

export default ElementSelector;
