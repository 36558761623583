import Header from './Header';
import Paragraph from './Paragraph';
import List from './List';

import browserme from 'browserme';

const HEADER_RATE = browserme.device.isDesktop() ? 0.5 : 0.2;
const LIST_RATE = browserme.device.isDesktop() ? 0.5 : 0.2;

const LINE_BREAK = {
    html: '\n',
    text: '\n\n',
    markdown: '\n\n'
};

const FINAL_BREAK = {
    html: '',
    text: '\n',
    markdown: '\n'
};

class ContentBlock {
    constructor(options) {
        this.elements = options.elements;
        this.index = options.index;

        this.init();
    }

    getIndex() {
        return this.index || 0;
    }

    init() {
        this.content = [
            Header.create({
                index: this.getIndex(),
                level: this.getHeaderLevel(),
                rate: this.getHeaderRate()
            }),
            Paragraph.create({
                index: this.getIndex(),
                level: this.getHeaderLevel(),
                showLinks: this.elements.link
            }),
            List.create({
                index: this.getIndex(),
                level: this.getHeaderLevel(),
                rate: this.getListRate()
            })
        ];
    }

    getHeaderRate() {
        return this.getRate(this.elements.header, this.index === 0 ? 1 : HEADER_RATE);
    }

    getHeaderLevel() {
        return this.index + 1;
    }

    getListRate() {
        return this.getRate(this.elements.list, LIST_RATE);
    }

    getRate(setting, rate) {
        return setting ? rate : 0;
    }

    getContent() {
        return this.content || [];
    }

    render(format) {
        return this.getContent().map(element => element.render(format)).filter(element => !element.empty);
    }

    toMarkup(format) {
        return this.getMarkup(format) + FINAL_BREAK[format];
    }

    getMarkup(format) {
        return this.content
            .map(block => block.toMarkup(format))
            .join(LINE_BREAK[format]);
    }
}

export default ContentBlock;
