import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SeparatorImg from '../../../images/separator.svg';

export const Sep = styled.img`
    width: 100%;
    margin-top: 35px;
    opacity: 0.5;

    &.inverted {
        transform: rotate(180deg);
        margin: 0;
    }
`;

const Separator = ({ style }) => (
    <Sep src={SeparatorImg} className={style} alt="" />
);

Separator.propTypes = {
    style: PropTypes.string
};

export default Separator;
