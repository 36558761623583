import styled from 'styled-components';

import { globals } from '../../globals/index';
import BlueCircle from '../../../images/blue-circle.svg';

export const LanguageNav = styled.nav`
    padding: 20px 0 0 0;
    margin-bottom: -50px;
    position: relative;

    @media (max-width: ${globals.sizes.bigPhablet}px) {
        padding: 0;
        margin-bottom: 0;
    } 
`;

export const LanguageList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: ${globals.sizes.bigPhablet}px) {
        display: block;
        position: absolute;
        top: 140px;
        right: 0;
        z-index: 10;
    }
`;

export const LanguageListElement = styled.li`
    flex: 0 0 auto;
    margin-left: 16px;
    position: relative;

    @media (max-width: ${globals.sizes.bigPhablet}px) {
        margin-bottom: 10px;
    }

    &.active {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -1px;
            left: -3px;
            width: 44px;
            height: 44px;
            z-index: 10;
            background-image: url(${BlueCircle});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
`;

export const LanguageAnchor = styled.a`
    font: 400 20px/10px ${globals.fonts.title};
    text-decoration: none;
    color: ${globals.colours.gray};
    text-transform: lowercase;
    display: inline-block;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    line-height: 40px;
    text-align: center;

    &.active {
        background-color: ${globals.colours.blue};
        color: ${globals.colours.active};
        position: relative;
        z-index: 1;
    }
`;
