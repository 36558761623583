import styled from 'styled-components';

import { ControlListElement, ControlAnchor } from './ControlBar.styles';
import { globals } from '../../globals/index';
import PinkCircle from '../../../images/pink-circle.svg';

export const ParagraphListElement = styled(ControlListElement)`
    &.active {
        background-color: ${globals.colours.pink};
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -4px;
            width: 45px;
            height: 45px;
            background-image: url(${PinkCircle});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
`;

export const ParagraphAnchor = styled(ControlAnchor)`
    &.active {
        color: #fff;
    }
`;
