export const HASHTAGS = {
    cachopu: 'cachopu',
    piedres: 'piedres',
    piesco: 'piesco',
    folixa: 'folixa',
    gaites: 'gaites',
    mantu: 'mantu',
    asgaya: 'asgaya',
    Cuadonga: 'Cuadonga',
    Frixuelos: 'Frixuelos',
    babayu: 'babayu'
};

class Texts {
    constructor() {
        this.sentences = [
            'Homen fium dolor sit folixa',
            'Xatu in pan cachopu est',
            'Pue más güeyum que butiellum',
            'Cagon mio mantum',
            'Piedres in Cuadonga rodarum asgaya',
            'Cutun fai escarabaya pelleyum',
            'Platun vendrás arbeyum, si nun xoven vieyum sedrá',
            'Fabes et sidrinam gasolinan nun fai falta',
            'Deus gaites da quien soplales nun pue',
            'Si besa-y culum perrum has, güeyum nun-y mires',
            'Piesco cestum tu est',
            'In Cuadonga batallam piedres orbayen',
            'Asgaya folixa, vidaya dolor est',
            'Onde naces nun yes, onde paces yes',
            'Gochum fartucum est',
            'Calla ho',
            'Regodones mil Europa refactoricé con',
            'Manqueme tirando piedres',
            'Conduzo ego, guíame ella',
            'Nun me seyas babayu',
            'Frixuelos y horros ties que deprender'
        ];

        this.length = this.sentences.length;
    }

    getSentence(index = 0) {
        if (index !== 0) {
            // First sentence is always the same in the first paragraph.
            index = Math.floor(Math.random() * this.length);
        }

        return this.sentences[index];
    }

    getNSentences(N, index = 0) {
        let content = [];

        for (let i = 0; i < N; i++) {
            content.push(this.getSentence(index + i));
        }

        return content;
    }
}

export default new Texts();
