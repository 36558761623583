import styled from 'styled-components';

import { globals } from '../../globals/index';

export const FormatNav = styled.nav`
    margin: 20px 0 0 0;
    position: relative;

    @media (max-width: ${globals.sizes.tablet}px) {
        display: flex;
        flex-direction: column;
    } 
`;

export const FormatList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    font-family: ${globals.fonts.title};
    font-size: 24px;
    line-height: 24px;

    @media (max-width: ${globals.sizes.tablet}px) {
        order: 2;
        justify-content: center;
    }

    @media (max-width: ${globals.sizes.mobile}px) {
        width: calc(100% + 50px);
        margin-left: -25px;
        margin-bottom: -10px;
    }
`;

export const FormatListElement = styled.li`
    padding: 0 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: ${globals.sizes.mobile}px) {
        margin-bottom: 10px;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 2px;
        height: 100%;
        background-color: ${globals.colours.pink};

        @media (max-width: ${globals.sizes.mobile}px) {
            display: none;
        }
    }

    &:first-child {
        &:before {
            display: none;
        }
    }

    &:last-child {
        padding-right: 0;
    }

    &.active:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - ${globals.padding}px);
        height: calc(100% - 4px);
        background-color: ${globals.colours.yellow};
        z-index: 0;
        transform: rotate(-3deg);
        margin-top: 2px;
    }
`;

export const FormatAnchor = styled.a`
    text-decoration: none;
    color: ${globals.colours.gray};

    &.active {
        color: ${globals.colours.active};
        position: relative;
        z-index: 1;
    }
`;
