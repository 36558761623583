import Clipboard from 'clipboard';
import browserme from 'browserme';

import { showCopied, hideCopied } from '../actions/index';

const C_KEY_CODE = 67;

class Events {
    constructor() {
        this.registerEvents();
    }

    load({ output, format }) {
        this.output = output;
        this.format = format;
    }

    setStore(store) {
        this.dispatch = store.dispatch;
    }

    registerEvents() {
        // Clipboard
        this.clipboard = new Clipboard('.control-c', {
            text: () => this.output.map(block => block.toMarkup(this.format)).join('\n')
        });

        // Document key down
        window.addEventListener('keydown', event => this.onKeyDown(event));
    }

    onKeyDown(event) {
        event = event || window.event;

        if (this.isControlKeyPressed(event) && event.keyCode === C_KEY_CODE) {
            this.copyToClipboard();
        }
    }

    copyToClipboard() {
        this.getCopy().click();
        this.dispatch(showCopied());
        setTimeout(() => this.dispatch(hideCopied()), 3000);
    }

    isControlKeyPressed(event) {
        return browserme.os.isMac()
            ? event.metaKey
            : event.ctrlKey;
    }

    getCopy() {
        if (!this.copy) {
            this.copy = document.querySelector('.control-c');
        }

        return this.copy;
    }
}

export default new Events();
