import CONSTANTS from './constants';
import { loadOutput } from './output';

const doChangeNumberParagraphs = number => ({
    type: CONSTANTS.CHANGE_NUMBER_PARAGRAPHS,
    number
});

export const changeNumberParagraphs = (number) => {
    return (dispatch) => {
        dispatch(doChangeNumberParagraphs(number));
        dispatch(loadOutput());
    };
};
