import styled from 'styled-components';

import { globals } from '../../globals/index';
import { Note } from './Note.styles';
import Arrow from '../../../images/arrow.svg';

export const NoteElements = styled(Note)`
    bottom: -170px;
    right: -200px;

    @media (max-width: ${globals.sizes.tablet}px) {
        bottom: auto;
        right: auto;
        margin: 0 0 20px 0;
        padding-left: 15px;
    }

    @media (max-width: ${globals.sizes.mobile}px) {
        position: relative;
        margin: 30px 0 10px 0;
        padding-left: 24px;
    } 

    &:before {
        content: '';
        position: absolute;
        width: 75px;
        height: 25px;
        top: -55px;
        left: 0;
        background-image: url(${Arrow});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(-150deg);

        @media (max-width: ${globals.sizes.tablet}px) {
            position: inherit;
            width: 100%;
            height: auto;
            top: auto;
            right: auto;
            left: -20px;
            content: '3';
            background-image: none;
            transform: none;
            font-size: 30px;
        }

        @media (max-width: ${globals.sizes.mobile}px) {
            position: absolute;
            left: -10px;
            top: 3px;
        }
    }
`;
