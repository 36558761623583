import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Translatable from '../../containers/Translatable';
import { globals } from '../../globals/index';

export const Copied = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    width: 550px;
    position: fixed;
    top: 0;
    left: 50%;
    bottom: 0;
    margin-left: -275px;
    font-family: ${globals.fonts.highlight};
    font-size: 100px;
    line-height: 120px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    transform: rotate(-10deg);
    color: ${globals.colours.darkGray};

    @media (max-width: ${globals.sizes.mobile}px) {
        width: 100%;
        left: 0;
        margin: 0;
        padding: 40px;
        font-size: 46px;
        line-height: 70px;
    } 

    &.shown {
        display: flex;
    }

    .emoji {
        white-space: nowrap;
    }
`;

const CopiedBlock = ({ copied }) => (
    <Copied className={copied ? 'shown' : ''}>
        <Translatable text="copied" />
        <span className="emoji">¯\_(ツ)_/¯</span>
    </Copied>
);

CopiedBlock.propTypes = {
    copied: PropTypes.bool.isRequired
};

export default CopiedBlock;
