import React from 'react';
import PropTypes from 'prop-types';

import { ControlNav, ControlList } from './ControlBar.styles';
import { ParagraphListElement, ParagraphAnchor } from './ParagraphSelector.styles';

import Translatable from '../../containers/Translatable';
import { NoteParagraphs } from '../Note/NoteParagraphs.styles';

export const PARAGRAPH_LIST = [1, 2, 3, 4, 5];

const ParagraphSelector = ({ paragraphs, changeNumberParagraphs }) => (
    <ControlNav>
        <ControlList>
            {PARAGRAPH_LIST.map((item, i) => {
                const index = i + 1;

                return (
                    <ParagraphListElement
                        key={index}
                        className={index === paragraphs ? 'active' : ''}
                    >
                        <ParagraphAnchor
                            href="#"
                            key={index}
                            className={index === paragraphs ? 'active' : ''}
                            onClick={e => {
                                e.preventDefault();
                                changeNumberParagraphs(index);
                            }}
                        >
                            {item}
                        </ParagraphAnchor>
                    </ParagraphListElement>
                );
            })}
        </ControlList>
        <NoteParagraphs>
            <Translatable text="select_p" />
        </NoteParagraphs>
    </ControlNav>
);

ParagraphSelector.propTypes = {
    changeNumberParagraphs: PropTypes.func.isRequired,
    paragraphs: PropTypes.number.isRequired
};

export default ParagraphSelector;
