import React from 'react';

import { Title } from './Header.styles';

const H1 = () => (
    <Title>
        @don_pelayu <span className="marker">#ipsum</span>
    </Title>
);

export default H1;
