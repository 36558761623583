import FormatSelector from '../components/FormatSelector/FormatSelector.component';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeFormat } from '../actions';

const mapStateToProps = function(state) {
    return {
        language: state.language,
        format: state.format
    };
};

const mapDispatchToProps = function(dispatch) {
    return bindActionCreators(
        {
            changeFormat
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormatSelector);
