import ContentBlock from '../content/ContentBlock';

import CONSTANTS from '../actions/constants';

const getOutput = state => {
    return [...Array(state.paragraphs).keys()].map(i => {
        return new ContentBlock({ ...state, index: i });
    });
};

const outputFilter = (state, action) => {
    switch (action.type) {
        case CONSTANTS.LOAD_OUTPUT:
            return getOutput(state);
        default:
            return state.output || [];
    }
};

export default outputFilter;
