import Word from './Word';
import Texts from '../utils/Texts';

const addTailSpace = sentence => [...sentence, '.'];

const addStop = sentence => [...sentence, '. '];

const addSpaces = sentence => sentence.split(' ').map(word => [word, ' ']).flat().slice(0, -1);

const addSpacesAndStop = (sentence) => addStop(addSpaces(sentence));

const formatWord = options => word => Word.create({ ...options, word });

const processOneSentence = (sentence, options) => addTailSpace(addSpaces(sentence).map(formatWord(options)));

const processSentencesItem = options => sentence => addSpacesAndStop(sentence).map(formatWord(options));

const processSentences = (sentences, options) => sentences.map(processSentencesItem(options)).flat();

const getRandomSentences = (number, index) => Texts.getNSentences(number, index);

const generateSentences = (number, index) => [getRandomSentences(number - 1, index), Texts.getSentence(1, index)];

const createSentences = (options = {}) => {
    const { number = 1, index } = options;
    const [first, last] = generateSentences(number, index);

    return [
        ...processSentences(first, options),
        ...processOneSentence(last, options)
    ];
};

export default {
    create: createSentences
};
