import styled from 'styled-components';

import { globals } from '../../globals/index';
import { Note } from './Note.styles';
import Arrow from '../../../images/arrow.svg';

export const NoteFormat = styled(Note)`
    top: -130px;
    right: -185px;

    @media (max-width: ${globals.sizes.tablet}px) {
        top: auto;
        right: auto;
        margin-bottom: 20px;
        padding-left: 35px;
    }

    @media (max-width: ${globals.sizes.mobile}px) {
        position: relative;
        padding-left: 24px;
    }

    &:before {
        content: '';
        position: absolute;
        width: 75px;
        height: 25px;
        top: 90px;
        left: -10px;
        background-image: url(${Arrow});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(135deg);

        @media (max-width: ${globals.sizes.tablet}px) {
            content: '1';
            position: inherit;
            width: 100%;
            height: auto;
            top: auto;
            left: -25px;
            background-image: none;
            transform: none;
            font-size: 30px;
        }

        @media (max-width: ${globals.sizes.mobile}px) {
            position: absolute;
            left: -7px;
        }
    }
`;
