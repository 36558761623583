import styled from 'styled-components';

import { globals } from '../../globals/index';

export const ControlBar = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding: 0 10px;

    @media (max-width: ${globals.sizes.mobile}px) {
        display: block;
        padding: 0;
        margin-top: 30px;
    }
`;

export const ControlNav = styled.nav`
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    font-family: ${globals.fonts.title};
    font-size: 24px;
    line-height: 40px;
    position: relative;

    @media (max-width: ${globals.sizes.tablet}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const ControlList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: ${globals.sizes.tablet}px) {
        order: 2;
    }
`;

export const ControlListElement = styled.li`
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background-color: ${globals.colours.lightGray};
    border-radius: 50%;
    text-align: center;

    &:last-child {
        margin-right: 0;
    }
`;

export const ControlAnchor = styled.a`
    text-decoration: none;
    color: ${globals.colours.gray};
    display: inline-block;
    width: 100%;
`;
