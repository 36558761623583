import styled from 'styled-components';

import { globals } from '../../globals/index';

export const AppWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    color: ${globals.colours.active};
    position: relative;
    z-index: 10;

    @media (max-width: ${globals.sizes.tablet}px) {
        display: block;
    } 
`;

export const AppMargin = styled.div`
    width: 200px;
    height: 100vh;
    flex-grow: 0;

    @media (max-width: ${globals.sizes.tablet}px) {
        display: none;
    } 
`;

export const App = styled.div`
    max-width: 900px;
    margin: 0 auto;
    flex-grow: 1;

    @media (max-width: ${globals.sizes.smallDesktop}px) {
        max-width: 700px;
    }

    @media (max-width: ${globals.sizes.tablet}px) {
        max-width: 850px;
        padding: 0 ${globals.padding}px; 
        overflow: hidden;
    }

    .link {
        color: ${globals.colours.pink};
        text-decoration: none;
      
        &:hover {
          text-decoration: underline;
        }
      }
`;
