import React from 'react';
import PropTypes from 'prop-types';

import { ControlBar } from './ControlBar.styles';

const ControlBarSection = ({ children }) => (
    <ControlBar className="control-bar">{children}</ControlBar>
);

ControlBarSection.propTypes = {
    children: PropTypes.node
};

export default ControlBarSection;
