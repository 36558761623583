import React from 'react';

import { SubTitle } from './Header.styles';
import Translatable from '../../containers/Translatable';

const H2 = () => (
    <SubTitle>
        <Translatable text="intro" />
    </SubTitle>
);

export default H2;
