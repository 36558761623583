import Element from './Element';

import Sentence from './Sentence';

const createHeader = (options = {}) => {
    const { rate, level, index } = options;

    return new Element({
        index: index,
        rate: rate,
        level: level,
        htmlTag: `h${level}`,
        inline: true,
        showLinks: false,
        tag: {
            html: {
                open: `<h${level}>`,
                close: `</h${level}>`
            },
            text: {
                open: `${level}. `,
                close: ''
            },
            markdown: {
                open: `${'#'.repeat(level)} `,
                close: ''
            }
        },
        content: Sentence.create({ number: 1, showLinks: false, index })
    });
};

export default {
    create: createHeader
};
