import styled from 'styled-components';

import { globals } from '../../globals/index';

export const Title = styled.h1`
    font-family: ${globals.fonts.title};
    font-size: 65px;
    line-height: 45px;
    text-align: left;
    margin: ${globals.padding}px 0;

    @media (max-width: ${globals.sizes.mobile}px) {
        font-size: 45px;
        line-height: 40px;
        margin: ${globals.padding}px 0 40px 0;
    }

    .marker {
        display: block;
        font-family: ${globals.fonts.highlight};
        font-size: 36px;
        color: ${globals.colours.pink};
        padding-left: 43%;
        margin-top: 15px;

        @media (max-width: ${globals.sizes.smallDesktop}px) {
            padding-left: 55%;
        }
    
        @media (max-width: ${globals.sizes.tablet}px) {
            padding-left: 47%;
        }
    
        @media (max-width: ${globals.sizes.mobile}px) {
            max-width: 360px;
            padding: 0;
            text-align: right;
        } 
      }
`;

export const Intro = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: justify-content;
    position: relative;
    left: -40px;

    @media (max-width: ${globals.sizes.tablet}px) {
        left: -20px;
    }

    @media (max-width: ${globals.sizes.mobile}px) {
        display: block;
        left: auto;
    }

    > * {
        flex-grow: 0;
        flex-shrink: 1;
    }
`;

export const SubTitle = styled.h2`
    font-family: ${globals.fonts.title};
    font-size: 18px;
    line-height: 38px;
    color: ${globals.colours.gray};
    margin: 0 0 80px 0;
    transform: rotate(-2deg);

    @media (max-width: ${globals.sizes.mobile}px) {
        margin: 20px 0 40px 0;
    }
`;

export const Pelayu = styled.img`
    width: 50%;
    height: 370px;
    max-width: 350px;
    position: relative;
    top: -30px;

    @media (max-width: ${globals.sizes.mobile}px) {
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        margin: 0 0 0 -10px;
    }
`;
