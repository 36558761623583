import { connect } from 'react-redux';

import Output from '../components/Output/Output.component';
import Events from '../utils/Events';

const mapStateToProps = function(state) {
    const output = state.output;
    const format = state.format;

    Events.load({ output, format });

    return {
        blocks: output,
        format: format
    };
};

export default connect(
    mapStateToProps
)(Output);
