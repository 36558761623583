import React from 'react';
import Link from './Link';

const createOneWord = (options = {}) => {
    const { word, showLinks = false } = options;
    const linkProbability = Link.shouldShowAsLink(showLinks, word);

    return linkProbability ? Link.create(options) : word;
};

export default {
    create: createOneWord
};
