import FooterWrapper from '../components/markup/FooterWrapper';

import { connect } from 'react-redux';

const mapStateToProps = function(state) {
    return {
        language: state.language
    };
};

export default connect(mapStateToProps)(FooterWrapper);
