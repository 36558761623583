import querystringme from 'querystringme';

import CONSTANTS from '../actions/constants';

const getInitialState = () => ({
    link: querystringme.getParameter('link') === 'true',
    header: querystringme.getParameter('header') === 'true',
    list: querystringme.getParameter('list') === 'true'
});

const elementFilter = (state = getInitialState(), action) => {
    switch (action.type) {
        case CONSTANTS.CHANGE_ELEMENT:
            let elements = Object.assign({}, state);
            elements[action.element] = action.status;

            let status = {};
            status[action.element] = action.status.toString();

            querystringme.updateParameters(status);
            return elements;
        default:
            return state;
    }
};

export default elementFilter;
