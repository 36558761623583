const CONSTANTS = {
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    CHANGE_NUMBER_PARAGRAPHS: 'CHANGE_NUMBER_PARAGRAPHS',
    CHANGE_FORMAT: 'CHANGE_FORMAT',
    CHANGE_ELEMENT: 'CHANGE_ELEMENT',
    LOAD_OUTPUT: 'LOAD_OUTPUT',
    SHOW_COPIED: 'SHOW_COPIED',
    HIDE_COPIED: 'HIDE_COPIED'
};

export default CONSTANTS;
