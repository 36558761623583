import Element from './Element';
import ListElement from './ListElement';

const SIZE = {
    min: 2,
    max: 5
};

const createList = (options = {}) => {
    const { rate, level, index } = options;
    const size = Math.floor(Math.random() * (SIZE.max - SIZE.min + 1)) + SIZE.min;

    return new Element({
        index: index,
        rate: rate,
        level: level,
        htmlTag: `ul`,
        inline: false,
        inlineChildren: true,
        wrapper: 'output-list',
        outerTag: true,
        childIndent: true,
        tag: {
            html: {
                open: `<ul>`,
                close: `</ul>`
            },
            text: {
                open: '',
                close: ''
            },
            markdown: {
                open: '',
                close: ''
            }
        },
        content: [...Array(size).keys()].map(i => ListElement.create({ index }))
    });
};

export default {
    create: createList
};
