import styled from 'styled-components';

import { globals } from '../../globals/index';

export const Note = styled.div`
    font: 300 20px/34px ${globals.fonts.title};
    color: ${globals.colours.bluePen};
    position: absolute;
    width: 160px;
    transform: rotate(-3deg);

    @media (max-width: ${globals.sizes.tablet}px) {
        position: inherit;
        width: 100%;
        order: 1;
        font-size: 18px;
        transform: rotate(-1deg);
    } 
`;
