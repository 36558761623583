import React from 'react';
import PropTypes from 'prop-types';

import {
    LanguageNav,
    LanguageList,
    LanguageListElement,
    LanguageAnchor
} from './LanguageSelector.styles';

export const LANGUAGE_LIST = ['ast', 'es', 'en'];

const LanguageSelector = ({ language, changeLanguage }) => (
    <LanguageNav>
        <div>
            <LanguageList>
                {LANGUAGE_LIST.map((item, i) => {
                    return (
                        <LanguageListElement
                            key={item}
                            className={item === language ? 'active' : ''}
                        >
                            <LanguageAnchor
                                href="#"
                                key={item}
                                className={item === language ? 'active' : ''}
                                onClick={e => {
                                    e.preventDefault();
                                    changeLanguage(item);
                                }}
                            >
                                {item}
                            </LanguageAnchor>
                        </LanguageListElement>
                    );
                })}
            </LanguageList>
        </div>
    </LanguageNav>
);

LanguageSelector.propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
};

export default LanguageSelector;
