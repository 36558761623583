import CONSTANTS from './constants';
import { loadOutput } from './output';

export const doChangeElement = (element, status) => ({
    type: CONSTANTS.CHANGE_ELEMENT,
    element,
    status
});

export const changeElement = (element, status) => {
    return (dispatch) => {
        dispatch(doChangeElement(element, status));
        dispatch(loadOutput());
    };
};
