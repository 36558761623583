import React from 'react';

import OutputContainer from '../../containers/OutputContainer';
import LanguageSelectorContainer from '../../containers/LanguageSelectorContainer';
import FormatSelectorContainer from '../../containers/FormatSelectorContainer';
import H1 from '../Header/H1.component';
import Intro from '../Header/Intro.component';
import ParagraphSelectorContainer from '../../containers/ParagraphSelectorContainer';
import Separator from '../markup/Separator';
import ControlBar from '../ControlBar/ControlBar.component';
import ElementSelectorContainer from '../../containers/ElementSelectorContainer';
import FooterWrapperContainer from '../../containers/FooterWrapperContainer';

import { App, AppWrapper, AppMargin } from './App.styles';

const AppComponent = () => (
    <AppWrapper>
        <AppMargin />
        <App>
            <LanguageSelectorContainer />
            <H1 />
            <Intro />
            <FormatSelectorContainer />
            <ControlBar>
                <ParagraphSelectorContainer />
                <ElementSelectorContainer />
            </ControlBar>
            <Separator />
            <OutputContainer />
            <FooterWrapperContainer />
        </App>
        <AppMargin />
    </AppWrapper>
);

export default AppComponent;
