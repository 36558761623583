import Copied from '../components/markup/Copied';

import { connect } from 'react-redux';

const mapStateToProps = function(state) {
    return {
        copied: state.copied,
        language: state.language
    };
};

export default connect(
    mapStateToProps
)(Copied);
