import React from 'react';
import PropTypes from 'prop-types';

import {
    FormatNav,
    FormatList,
    FormatListElement,
    FormatAnchor
} from './FormatSelector.styles';

import Translatable from '../../containers/Translatable';
import { NoteFormat } from '../Note/NoteFormat.styles';

export const FORMAT_LIST = {
    html: {
        key: 'html',
        name: '<html>'
    },
    text: {
        key: 'text',
        name: '*text'
    },
    markdown: {
        key: 'markdown',
        name: 'markdown↓'
    }
};

const FormatSelector = ({ format, changeFormat }) => (
    <FormatNav>
        <FormatList>
            {Object.keys(FORMAT_LIST).map((key, i) => {
                const name = FORMAT_LIST[key].name;

                return (
                    <FormatListElement
                        key={key}
                        className={key === format ? 'active' : ''}
                    >
                        <FormatAnchor
                            href="#"
                            key={key}
                            className={key === format ? 'active' : ''}
                            onClick={e => {
                                e.preventDefault();
                                changeFormat(key);
                            }}
                        >
                            {name}
                        </FormatAnchor>
                    </FormatListElement>
                );
            })}
        </FormatList>
        <NoteFormat>
            <Translatable text="select_format" />
        </NoteFormat>
    </FormatNav>
);

FormatSelector.propTypes = {
    format: PropTypes.string.isRequired,
    changeFormat: PropTypes.func.isRequired
};

export default FormatSelector;
