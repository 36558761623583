import React from 'react';

import Translatable from '../../containers/Translatable';

import cruzImg from '../../../images/cruz.svg';
import juanImg from '../../../images/juan_castro.png';
import asturiesImg from '../../../images/asturies.svg';

import {
    Footer,
    Asturies,
    Signature,
    MadeIn,
    Designed,
    Cruz,
    JuanCastro,
    License
} from './Footer.styles';

const FooterSection = () => (
    <Footer>
        <Signature>
            <Asturies src={asturiesImg} alt="" />
            <MadeIn>
                <Cruz src={cruzImg} className="cruz" alt="" />
                <Translatable text="made" />
            </MadeIn>
            <Designed>
                <Translatable text="design" />
                <a href="http://juancastro.es">
                    <JuanCastro
                        src={juanImg}
                        alt="juancastro.es"
                    />
                </a>
            </Designed>
        </Signature>
        <License>
            <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
                <img alt="Licencia Creative Commons" src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png" />
            </a>
            <div>
                <span>
                    <Translatable text="license_intro" />
                    <a rel="license" className="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
                        <Translatable text="license" />
                    </a>
                </span>
                <span>
                    <Translatable text="license_images" />
                </span>
            </div>
        </License>
    </Footer>
);

export default FooterSection;
