import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import querystring from '../utils/querystring';
import languageFilter from './language';
import numberParagraphFilter from './paragraphs';
import formatFilter from './format';
import elementFilter from './elements';
import outputFilter from './output';
import copiedFilter from './copied';

querystring.load();

const reducers = (state = {}, action) => {
    return {
        language: languageFilter(state.language, action),
        paragraphs: numberParagraphFilter(state.paragraphs, action),
        format: formatFilter(state.format, action),
        elements: elementFilter(state.elements, action),
        output: outputFilter(state, action),
        copied: copiedFilter(state.copied, action)
    };
};

export const store = createStore(reducers, applyMiddleware(thunk));
