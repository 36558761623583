import styled from 'styled-components';
import { darken } from 'polished';

import { ControlNav, ControlListElement, ControlAnchor, ControlList } from './ControlBar.styles';
import { globals } from '../../globals/index';
import BlueCircle from '../../../images/blue-circle.svg';

export const ElementControlList = styled(ControlList)`
    @media (max-width: ${globals.sizes.mobile}px) {
        justify-content: space-around;
    }
`;

export const ElementNav = styled(ControlNav)`
    font-size: 18px;
`;

export const ElementListElement = styled(ControlListElement)`
    &.active {
        background-color: ${globals.colours.blue};
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -2px;
            width: 45px;
            height: 45px;
            background-image: url(${BlueCircle});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
`;

export const ElementAnchor = styled(ControlAnchor)`
    &.active {
        color: ${darken(0.3, globals.colours.blue)};
        position: relative;
        z-index: 2;
    }
`;
