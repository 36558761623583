import React from 'react';
import Element from './Element';
import { HASHTAGS } from '../utils/Texts';

const LINK_RATE = 0.2;

const isLinkProbability = () => Math.random() >= 1.0 - LINK_RATE;

const shouldShowAsLink = (showLinks, tag) => showLinks && HASHTAGS[tag] && isLinkProbability();

const createLink = (options = {}) => {
    const { rate, level, index, word } = options;
    const url = `https://twitter.com/hashtag/${word}`;

    return new Element({
        index: index,
        rate: rate,
        level: level,
        htmlTag: 'a',
        inline: true,
        always: true,
        className: 'anchor',
        attributes: {
            href: url,
            target: '_blank'
        },
        tag: {
            html: {
                open: (<React.Fragment>&lt;a href="<i>{url}</i>"&gt;</React.Fragment>),
                close: '</a>',
                rawOpen: `<a href="${url}">`
            },
            text: {
                open: '',
                close: ` (${url})`
            },
            markdown: {
                open: '[',
                close: `](${url})`
            }
        },
        content: [word]
    });
};

export default {
    create: createLink,
    shouldShowAsLink
};
