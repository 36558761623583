import querystringme from 'querystringme';

import CONSTANTS from '../actions/constants';

const getInitialState = () => querystringme.getParameter('format');

const formatFilter = (state = getInitialState(), action) => {
    switch (action.type) {
        case CONSTANTS.CHANGE_FORMAT:
            querystringme.updateParameters({
                format: action.format
            });
            return action.format;
        default:
            return state;
    }
};

export default formatFilter;
