import renderHTML from 'react-render-html';

class Translations {
    constructor() {
        this.translations = {
            ast: {
                intro:
                    'Nun quedaben piedres en <a href="https://twitter.com/hashtag/cuadonga" target="blank" className="link">#Cuadonga</a> y yá nun sabía que faer... Asina que di un xiru a la mio vida y agora soi Desenvolvedor Ueb. Y para ayudar na nuesa xera fice esta ferramienta de xeneración de testos.<p>Pues seguime en <a href="https://twitter.com/don_pelayu" target="blank" className="link">@don_pelayu</a>.</p>',
                select_p: "escueye'l númberu de párrafos que deseyes",
                select_format: "escueye'l formatu",
                select_elements: 'calca los elementos que precises',
                copy_ctrl:
                    'pulsia <span class="cmd">control + c</span> para copiar el testu',
                copy_cmd: 'pulsia <span class="cmd">cmd + c</span> para copiar el testu',
                copy_mobile: 'fai clic equí para copiar el testu',
                made: "fechu n'Asturies",
                design: 'diseñáu y fechu por',
                copied: "copióse'l testu",
                license_intro: 'Esta obra ta baxu una llicencia',
                license: 'Creative Commons Atribución-NoComercial-EnsinDerivaes 4.0 Internacional.',
                license_images: 'Queden reservaos tolos derechos sobro les imaxes.'
            },
            es: {
                intro:
                    'No quedaban piedras en <a href="https://twitter.com/hashtag/cuadonga" target="blank" className="link">#Covadonga</a> y ya no sabía que hacer... Así que di un giro a mi vida y ahora soy Desarrollador Web. Y para ayudar en nuestra tarea he hecho esta herramienta de generación de textos.<p>Puedes seguirme en <a href="https://twitter.com/don_pelayu" target="blank" className="link">@don_pelayu</a>.</p>',
                select_p: 'escoge el número de párrafos que desees',
                select_format: 'escoge el formato',
                select_elements: 'marca los elementos que necesitas',
                copy_ctrl:
                    'pulsa <span class="cmd">control + c</span> para copiar el texto',
                copy_cmd: 'pulsa <span class="cmd">cmd + c</span> para copiar el texto',
                copy_mobile: 'haz clic aquí para copiar el texto',
                made: 'hecho en Asturias',
                design: 'diseñado y hecho por',
                copied: 'Se ha copiado el texto',
                license_intro: 'Esta obra está bajo una licencia',
                license: 'Creative Commons Atribución-NoComercial-SinDerivadas 4.0 Internacional.',
                license_images: 'Quedan reservados todos los derechos sobre las imágenes.'
            },
            en: {
                intro:
                    'There were no stones in <a href="https://twitter.com/hashtag/cuadonga" target="blank" className="link">#Covadonga</a> and I did not know what to do... So I turned my life around and now I\'m a Web Developer. And I created this tool to help in our daily tasks of generating texts.<p>You can follow me on <a href="https://twitter.com/don_pelayu" target="blank" className="link">@don_pelayu</a>.</p>',
                select_p: 'select the number of paragraphs you want',
                select_format: 'select the format',
                select_elements: 'choose the elements you need',
                copy_ctrl: 'type <span class="cmd">control + c</span> to copy the text',
                copy_cmd: 'type <span class="cmd">cmd + c</span> to copy the text',
                copy_mobile: 'click here to copy the text',
                made: 'made in Asturias',
                design: 'designed and made by',
                copied: 'text has been copied',
                license_intro: 'This work is licensed under a license',
                license: 'Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International.',
                license_images: 'All rights reserved for every image.'
            }
        };
    }

    getTranslations(language) {
        return this.translations[language];
    }

    getTranslation(language, key) {
        const translations = this.getTranslations(language);

        return translations ? translations[key] : null;
    }

    getTranslationHTML(language, key) {
        const translation = this.getTranslation(language, key);

        return translation ? renderHTML(translation) : '';
    }
}

export default new Translations();
