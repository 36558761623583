import React from 'react';
import { render } from 'react-dom';

import App from './components/App/App.component';

import { Provider } from 'react-redux';
import { store } from './reducers/index';
import Events from './utils/Events';
import { loadOutput } from './actions/index';

const domRefs = {
    app: document.getElementById('app'),
    temp: document.getElementById('temp')
};

render(
    <Provider store={store}>
        <App />
    </Provider>,
    domRefs.app
);

Events.setStore(store);

store.dispatch(loadOutput());
