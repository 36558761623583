import React from 'react';
import whatsme from 'whatsme';

import { FORMAT_LIST } from '../components/FormatSelector/FormatSelector.component';

const INDENTATION = {
    html: '\t',
    text: '',
    markdown: ''
};

class Element {
    constructor(options = {}) {
        this.options = options;
    }

    getTag(format = '') {
        return this.options.tag[format] || {};
    }

    getOpenTag(format = '') {
        return this.getTag(format).open;
    }

    getCloseTag(format = '') {
        return this.getTag(format).close;
    }

    getRawOpenTag(format = '') {
        return this.getTag(format).rawOpen || this.getOpenTag(format);
    }

    getRawCloseTag(format = '') {
        return this.getTag(format).rawClose || this.getCloseTag(format);
    }

    getHtmlTag() {
        return this.options.htmlTag || React.Fragment;
    }

    getAttributes() {
        return this.options.attributes || {};
    }

    getContent() {
        return this.options.content || [];
    }

    getChildrenContent(format) {
        return this.getContent()
            .map(element => element.render ? element.render(format) : element);
    }

    getRate() {
        return whatsme.isDefined(this.options.rate) ? this.options.rate : 1;
    }

    getIndex() {
        return this.options.index || 0;
    }

    getInline() {
        return this.options.inline || false;
    }

    getInlineChildren() {
        return this.options.inlineChildren || false;
    }

    getIndent() {
        return this.options.indent || false;
    }

    getChildIndent() {
        return this.options.childIndent || false;
    }

    getClassName() {
        return this.options.className || '';
    }

    getAlways() {
        return this.options.always || false;
    }

    getWrapper() {
        return this.options.wrapper;
    }

    getOuterTag() {
        return this.options.outerTag || false;
    }

    render(format = FORMAT_LIST.html.key) {
        return this.mustRender() ? this.getRenderInfo(format) : { empty: true };
    }

    getRenderInfo(format) {
        return {
            tag: this.getHtmlTag(),
            attributes: this.getAttributes(),
            inline: this.getInline(),
            className: this.getClassName(),
            wrapper: this.getWrapper(),
            outerTag: this.getOuterTag(),
            format: {
                open: this.getOpenTag(format),
                close: this.getCloseTag(format),
                name: format
            },
            content: this.getChildrenContent(format)
        };
    }

    toMarkup(format) {
        return [
            this.getRawOpenTag(format),
            this.getChildrenMarkup(format),
            this.getRawCloseTag(format)
        ].filter(element => element !== '')
            .join(this.getInline() ? '' : '\n');
    }

    getChildrenMarkup(format) {
        return this.getContentIndentation(format) +
            this.getContent()
                .map(child => this.getElementMarkup(child, format))
                .join(this.getInlineChildren() ? '\n' : '');
    }

    getElementMarkup(element, format) {
        return element.toMarkup
            ? this.getElementIndentation(format) + element.toMarkup(format)
            : element.toString();
    }

    getContentIndentation(format) {
        return this.getIndent() ? INDENTATION[format] : '';
    }

    getElementIndentation(format) {
        return this.getChildIndent() ? INDENTATION[format] : '';
    }

    mustRender() {
        return this.getAlways() || this.checkRate();
    }

    checkRate() {
        return this.getRate() > 0 && Math.random() >= 1 - this.getRate() * 1;
    }
}

export default Element;
