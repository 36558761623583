import styled from 'styled-components';

import { globals } from '../../globals/index';

export const Footer = styled.footer`
    margin: 150px 0 30px 0;

    @media (max-width: ${globals.sizes.tablet}px) {
        width: calc(100% + 50px);
        margin-left: -${globals.padding}px;
    } 

    @media (max-width: ${globals.sizes.mobile}px) {
        margin-bottom: 30px;
    } 
`;

export const Signature = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font: 300 20px/24px ${globals.fonts.title};
    color: ${globals.colours.blueSignature};

    @media (max-width: ${globals.sizes.mobile}px) {
        flex-direction: row;
    }
`;

export const MadeIn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: ${globals.sizes.tablet}px) {
        margin-left: ${globals.padding}px;
    } 

    @media (max-width: ${globals.sizes.mobile}px) {
        width: 100%;
        justify-content: center;
        margin-top: 30px;
        margin-left: 0;
        order: 3;
    }
`;

export const Cruz = styled.img`
    height: 50px;
    margin-right: 10px;

    @media (max-width: ${globals.sizes.mobile}px) {
        height: 35px;
    }
`;

export const Designed = styled.div`
    text-align: right;
    font-size: 17px;
    line-height: 20px;
    position: relative;
    top: -4px;

    img {
        opacity: 0.8;
    }

    @media (max-width: ${globals.sizes.tablet}px) {
        margin-right: ${globals.padding}px;
    } 

    @media (max-width: ${globals.sizes.mobile}px) {
        width: 100%;
        margin-right: 0;
        text-align: center;
        order: 2;
    } 
`;

export const Asturies = styled.img`
    width: 80%;
    opacity: 0.5;
    margin: 0 auto 40px auto;

    @media (max-width: ${globals.sizes.smallDesktop}px) {
        width: 100%;
    } 

    @media (max-width: ${globals.sizes.mobile}px) {
        width: 120%;
        margin-left: -10%;
        margin-right: -10%;
        order: 2;
    }
`;

export const JuanCastro = styled.img`
    display: block;
    width: 200px;
    margin-top: 5px;

    @media (max-width: ${globals.sizes.mobile}px) {
        margin: 10px auto 0 auto;
    } 
`;

export const License = styled.section`
    text-align: right;
    color: ${globals.colours.blueSignature};
    font: 300 12px/16px ${globals.fonts.title};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 80px 0 0 0;

    @media (max-width: ${globals.sizes.tablet}px) {
        padding: 0 ${globals.padding}px;
    }

    @media (max-width: ${globals.sizes.mobile}px) {
        flex-direction: column;
        align-items: center;
        padding: 0 25px;
        text-align: center;
    }

    .license {
        color: ${globals.colours.blueSignature};
        text-decoration: none;
        margin: 0 6px;

        &:hover {
            text-decoration: underline;
        }
    }

    img {
        margin: 2px 10px 0 0;
        opacity: 0.7;

        @media (max-width: ${globals.sizes.mobile}px) {
            margin-bottom: 10px;
        }
    }
`;
